
<div class="aside" [ngSwitch]="asideTemplate">

  <!-- Switch Case 1 -->
  <!-- -------------------- -->
  <div *ngSwitchCase="'option1'">

  </div>
  
  <!-- Switch Case 2 -->
  <!-- -------------------- -->
  <div *ngSwitchCase="'option1'">

  </div>

  <!-- Default Switch Case -->
  <!-- -------------------- -->
  <div *ngSwitchDefault>

    <!-- Header -->
    <!-- ------------ -->
    <h1 class="header">
      Aside Header
    </h1>
    
    <!-- Content -->
    <!-- ------------ -->
    <div class="content">
      {{dummyText}}
    </div>
  
    <!-- Action Buttons -->
    <!-- -------------- -->
    <div class="actionBtn">
      <button mat-button (click)="onClose.emit()">Cancel</button>
      <button mat-button>Save</button>
    </div>
  </div>
  
</div>
