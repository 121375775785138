import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DUMMY_TEXT } from '../lightbox/dummy-text.const';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit {

  @Input() asideTemplate: string;
  @Output() onClose = new EventEmitter();

  readonly dummyText: string = DUMMY_TEXT;
  
  constructor() { }

  ngOnInit() {
  }

}
